/* eslint-disable react/react-in-jsx-scope */
import { lazy } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import PublicRoute from './PublicRoute.jsx';
import PrivateRoute from './PrivateRoute.jsx';
import AuthRoute from './AuthRoute.jsx';

const Login = lazy(() => import('#pages/auth/Login.js'));
const LoginVerify = lazy(() => import('#pages/auth/LoginVerify.js'));
const LoginToken = lazy(() => import('#pages/auth/LoginToken.js'));
const Logout = lazy(() => import('#pages/auth/Logout.js'));
const RegisterVerify = lazy(() => import('#pages/auth/RegisterVerify.js'));
const ForgetPassword = lazy(() => import('../pages/auth/ForgetPassword.js'));
const ResetPassword = lazy(() => import('../pages/auth/ResetPassword.js'));
const NoticeVerifyEmail = lazy(() => import('../pages/auth/NoticeVerifyEmail.js'));
const VerifyEmail = lazy(() => import('../pages/auth/VerifyEmail.js'));
const NoFound = lazy(() => import('../pages/auth/NoFound.js'));
const RegisterAccount = lazy(() => import('../pages/auth/RegisterAccount.js'));
const Unsubscribe = lazy(() => import('../pages/auth/Unsubscribe.js'));
const NewBoost = lazy(() => import('../pages/apps/Manage/NewBoost.js'));
const DepositHistory = lazy(() => import('../pages/apps/Manage/DepositHistory.js'));
const RepaymentBoost = lazy(() => import('../pages/apps/Manage/RepaymentBoost.js'));
const RepaymentSchedule = lazy(() => import('../pages/apps/Manage/RepaymentSchedule.js'));
const SetNotification = lazy(() => import('../pages/apps/ResponsibleBetting/SetNotification.js'));
// Card Manager Routes
const ViewCard = lazy(() => import('../pages/apps/card/ViewCard.js'));
const PaymentMethods = lazy(() => import('../pages/apps/card/PaymentMethods.js'));
const CardTransactions = lazy(() => import('../pages/apps/card/CardTransactions.js'));
const AccountProfile = lazy(() => import('../pages/my-account/Profile.js'));
// const AccountVerification = lazy(() => import('../pages/apps/Account/Verification'));
const StatementProfile = lazy(() => import('../pages/my-account/Statement.js'));
const DepositFunds = lazy(() => import('#pages/apps/funds/Deposit.js'));
const CardDeposit = lazy(() => import('#pages/apps/funds/CardDeposit.js'));
const DemonstrationTutorial = lazy(() => import('../pages/tutorial/Demonstration.js'));
const Legal = lazy(() => import('../pages/apps/Legal/Legal.js'));
const Repayment = lazy(() => import('../pages/apps/simulator/repaymentSimulator.js'));
const GalileoSimulator = lazy(() => import('../pages/apps/simulator/Authorization.js'));
const RegisterSSN = lazy(() => import('../pages/signup/SSN.js'));
const RegisterVerifyEmail = lazy(() => import('../pages/signup/VerifyEmail.js'));
const Waitlist = lazy(() => import('../pages/demo/Waitlist.js'));
const SetupStart = lazy(() => import('../pages/setup/Start.js'));
const VerifyIdentity = lazy(() => import('../pages/setup/VerifyIdentity.jsx'));
const RunKYC = lazy(() => import('../pages/setup/KYC'));
const SetupComplete = lazy(() => import('../pages/setup/Complete.js'));
const DepositAgreements = lazy(() => import('../pages/setup/DepositAgreements.js'));
const SetupBankAccount = lazy(() => import('../pages/setup/LinkBankAccount.js'));
const WithdrawalPage = lazy(() => import('#pages/apps/funds/Withdrawal.js'));
const DepositConfirmationPage = lazy(() => import('#pages/apps/funds/DepositConfirmation.js'));
const AppRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                {/* PUBLIC ROUTES */}
                <Route path="*" element={<PublicRoute />}>
                    <Route path="*" element={<NoFound />} />
                </Route>
                <Route path="/simulate" element={<PublicRoute />}>
                    <Route path="/simulate/repayment" element={<Repayment />} />
                    <Route path="/simulate/galileo" element={<GalileoSimulator />} />
                </Route>
                <Route path="/waitlisted" element={<PublicRoute />}>
                    <Route path="/waitlisted" element={<Waitlist />} />
                </Route>
                <Route path="/legal" element={<PublicRoute />}>
                    <Route path="/legal/documents" element={<Legal />} />
                </Route>
                {/* -------------- */}
                {/* APP ROUTES */}
                <Route path="/" element={<PrivateRoute />}>
                    <Route exact path="/" element={<Navigate to="/tutorials/demo" replace />} />
                    <Route path="/card/view" element={<ViewCard />} />
                    <Route path="/card/transactions" element={<CardTransactions />} />
                    <Route path="/boosts/repay-boost" element={<RepaymentBoost />} />
                    <Route path="/boosts/repayment-schedule" element={<RepaymentSchedule />} />
                    <Route path="/betting/notification" element={<SetNotification />} />
                    <Route path="/tutorials/demo" element={<DemonstrationTutorial />} />
                    <Route path="/account/profile" element={<AccountProfile />} />
                    <Route path="/account/statement" element={<StatementProfile />} />
                    <Route path="/account/deposit-history" element={<DepositHistory />} />
                    <Route path="/deposit/card_deposit" element={<CardDeposit />} />
                    <Route path="/deposit/bank_deposit" element={<DepositFunds />} />
                    <Route path="/deposit/confirmation" element={<DepositConfirmationPage />} />
                    <Route path="/funds/boost" element={<NewBoost />} />
                    <Route path="/funds/withdraw" element={<WithdrawalPage />} />
                    <Route path="/bank/accounts" element={<PaymentMethods />} />
                </Route>
                {/* ------------ */}
                {/* AUTH ROUTES  */}
                <Route path="/account" element={<AuthRoute />}>
                    <Route path="/account/login" element={<Login />} />
                    <Route path="/account/login-token" element={<LoginToken />} />
                    <Route path="/account/login-verify" element={<LoginVerify />} />
                    <Route path="/account/logout" element={<Logout />} />
                    <Route path="/account/register" element={<RegisterAccount />} />
                    <Route path="/account/register-token" element={<RegisterVerify />} />
                    <Route path="/account/forget-password" element={<ForgetPassword />} />
                    <Route path="/account/verify-email" element={<NoticeVerifyEmail />} />
                    <Route path="/account/verify/:token" element={<VerifyEmail />} />
                    <Route path="/account/reset-password/:token" element={<ResetPassword />} />
                    <Route path="/account/unsubscribe/:token" element={<Unsubscribe />} />
                </Route>
                {/* ------------ */}
                {/* SETUP ROUTES */}
                <Route path="/setup" element={<PrivateRoute />}>
                    <Route exact path="/setup/verify-email/:token" element={<RegisterVerifyEmail />} />
                    <Route exact path="/setup/verify-email" element={<RegisterVerifyEmail />} />
                    <Route path="/setup/email-verified" element={<SetupStart />} />
                    <Route path="/setup/deposit-agreements" element={<DepositAgreements />} />
                    <Route path="/setup/link-bank-account" element={<SetupBankAccount />} />
                    <Route path="/setup/customer-info" element={<VerifyIdentity />} />
                    <Route path="/setup/customer-ssn" element={<RegisterSSN />} />
                    <Route path="/setup/kyc-in-progress" element={<RunKYC />} />
                    <Route path="/setup/add-to-crb" element={<SetupComplete />} />
                    <Route path="/setup/add-to-galileo" element={<SetupComplete />} />
                    <Route path="/setup/complete" element={<SetupComplete />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default AppRoutes;
